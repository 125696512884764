import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import {Router, RouterModule} from '@angular/router';
import {ComponentsModule} from '../components.module';
import * as _ from 'underscore';
import {MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {AuthorComponent} from '../author/author.component';
import {Search, SearchRequest, SearchService} from 'src/@backend';
import {firstValueFrom} from "rxjs";
import {ClientBasicComponent} from "../client-basic/client-basic.component";
import {UserCollaboratorProfileComponent} from "../user-collaborator-profile/user-collaborator-profile.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FormsModule} from "@angular/forms";
import {SingleAuthorComponent} from "../single-author/single-author.component";
import {ClientExtendedComponent} from "../client-extended/client-extended.component";

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatRippleModule, RouterModule, ComponentsModule, MatDialogModule, MatSelectModule, AuthorComponent, ClientBasicComponent, UserCollaboratorProfileComponent, MatTooltipModule, FormsModule, SingleAuthorComponent, ClientExtendedComponent],
  selector: 'kodl-search-modal',
  template: `
    <div class="">
      <div class="flex items-center gap-4 px-6 py-3 border-b border-divider">
        <mat-icon svgIcon="mat:search" class="text-secondary flex-none"></mat-icon>
        <input (keyup)="doSearch($event)" type="text" placeholder="Vyhledat..." [(ngModel)]="searchRequest.request" class="text-xl font-medium bg-transparent outline-none flex-auto placeholder-secondary"/>
        <button class="flex-none ltr:-mr-2 rtl:-ml-2 text-secondary" type="button" (click)="clear()" mat-icon-button>
          <mat-icon svgIcon="mat:cancel"></mat-icon>
        </button>
      </div>

@if (searchResult) {
<div class="searchResult overscroll-y-contain overflow-y-auto" style="max-height: 80vh;">

  @if(searchResult.clients && searchResult.clients.length > 0) {
    <div class="p-4">
      <div class="text-xs font-semibold text-secondary px-2 mb-2">Klienti</div>

      <div class="space-y-1">
        @for(client of searchResult.clients; track client.id) {
          <div class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
               (click)="openClientDetail(client.id, false, $event)" matRipple>
            <div class="flex flex-row flex-auto text-base font-medium items-center gap-2">
              <div><canvas-client-extended [client]="client"></canvas-client-extended></div>
              <div class="flex flex-row text-secondary text-xs gap-1">
                @if(client.attenderId_1) {
                  <canvas-user-collaborator-profile [type]="'modUser'" [id]="client.attenderId_1" [avatarOnly]="true"></canvas-user-collaborator-profile>
                }
                @if(client.attenderId_2) {
                  <canvas-user-collaborator-profile [type]="'modUser'" [id]="client.attenderId_2" [avatarOnly]="true"></canvas-user-collaborator-profile>
                }
              </div>

              @if(client.tags) {
                <canvas-client-tags class="flex flex-row" [tags]="client.tags"></canvas-client-tags>
              }
            </div>
            <button class="hover:text-blue-500 mx-2 w-8 h-8 items-center" matTooltip="Otevřít v novém okně" (click)="openClientDetail(client.id,  true, $event)"><mat-icon svgIcon="mat:open_in_new" class="icon-sm flex-none"></mat-icon></button>
            <button class="hover:text-blue-500 mx-2 w-8 h- items-center" matTooltip="Otevřít"><mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon></button>
          </div>
        }
      </div>
    </div>
  }

  @if (searchResult.items && searchResult.items.length > 0) {
  <div class="p-4">
    <div class="text-xs font-semibold text-secondary px-2 mb-2">Položky</div>
    <div class="space-y-1">
      @for (item of searchResult.items; track item.id) {
      <div class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
           (click)="openItemDialog(item.id, false, $event)"
           matRipple>
        <div class="flex items-center justify-center w-10 h-10 rounded-full bg-foreground/20">
          <img [alt]="item.name" class="h-auto w-max align-middle" [src]='item.photo|photoSecured:"1":"/plain/rs:fit:200:200:1/g:no/":item.shared | async' />
        </div>

        <div class="flex flex-col flex-auto text-base font-medium">
          <canvas-item-labels [itemId]="item.id" [prefixNumber]="item?.contract?.prefixNumber" [catalogNumber]="item.catalogNumber" [auctionNumber]="item.auctionterms" [showSharedLabel]="false"></canvas-item-labels>
          <div [innerHTML]="item.name"></div>
          <div class="text-secondary text-xs">
            <strong><kodl-author [authors]="item.authors" [showDating]="true"></kodl-author></strong><br>
            <i>{{item.datace}}</i>
          </div>
        </div>
        <button class="hover:text-blue-500 mx-2 w-8 h-8 items-center" matTooltip="Otevřít v novém okně" (click)="openItemDialog(item.id, true, $event)"><mat-icon svgIcon="mat:open_in_new" class="icon-sm flex-none"></mat-icon></button>
        <button class="hover:text-blue-500 mx-2 w-8 h-8 items-center" matTooltip="Otevřít"><mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon></button>
      </div>
      }

    </div>
  </div>
  }




  @if(searchResult.authors && searchResult.authors.length > 0) {
    <div class="p-4">
      <div class="text-xs font-semibold text-secondary px-2 mb-2">Autoři</div>

      <div class="space-y-1">
        @for(author of searchResult.authors; track author.id) {
          <div class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
               (click)="openAuthorDetail(author.id, false, $event)" matRipple>
            <div class="flex flex-row flex-auto text-base font-medium items-center gap-2">
              <div><kodl-single-author [author]="author"></kodl-single-author></div>
<!--              <div class="flex flex-row text-secondary text-xs gap-1">-->
<!--              </div>-->
            </div>
            <button class="hover:text-blue-500 mx-2 w-8 h-8 items-center" matTooltip="Otevřít v novém okně" (click)="openAuthorDetail(author.id,  true, $event)"><mat-icon svgIcon="mat:open_in_new" class="icon-sm flex-none"></mat-icon></button>
            <button class="hover:text-blue-500 mx-2 w-8 h- items-center" matTooltip="Otevřít"><mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon></button>
          </div>
        }
      </div>
    </div>
  }

  @if(searchResult.contracts && searchResult.contracts.length > 0) {
    <div class="p-4">
      <div class="text-xs font-semibold text-secondary px-2 mb-2">Smlouvy</div>

      <div class="space-y-1">
        @for(contract of searchResult.contracts; track contract.id) {
          <div class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
               (click)="openContractDetail(contract.id, false, $event)" matRipple>
            <div class="flex flex-row flex-auto text-base font-medium items-center gap-2">
              <div class="flex flex-col gap-1">
                {{contract.id}}{{contract.prefixNumber}}
                <div class="flex flex-row text-secondary text-xs gap-2">
                  @for(client of contract.clients; track client.id) {
                    <canvas-client-basic [client]="client" class="hover:bg-gray-300 p-2 rounded"></canvas-client-basic>
                  }
                </div>
              </div>
            </div>
            <button class="hover:text-blue-500 mx-2 w-8 h-8 items-center" matTooltip="Otevřít v novém okně" (click)="openContractDetail(contract.id,  true, $event)"><mat-icon svgIcon="mat:open_in_new" class="icon-sm flex-none"></mat-icon></button>
            <button class="hover:text-blue-500 mx-2 w-8 h- items-center" matTooltip="Otevřít"><mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon></button>
          </div>
        }
      </div>
    </div>
  }


</div>
}
</div>
  `,
  styles: [`
  `]
})
export class SearchModalComponent implements OnInit {
  searchRequest: SearchRequest;
  searchResult: Search;
  loadingData = false;

  constructor(private searchService: SearchService, private dialog: MatDialog, private dialogRef: MatDialogRef<SearchModalComponent>) {
    this.searchRequest = {} as SearchRequest;

    // Persist search data
    const searchRequest = localStorage.getItem('search.request');
    if(searchRequest) {
      try {
        this.searchResult = JSON.parse(localStorage.getItem('search.result') || '{}') as Search;
        this.searchRequest = {request: searchRequest} as SearchRequest;
      } catch (error) {
        console.error('Error parsing localStorage data', error);
        this.clear();
      }
    }

  }

  ngOnInit(): void {
    // this.getData = _.debounce(this.getData, 300);
  }

  async getData(value: string) {
    console.log('getData', value);
    this.loadingData = true;
    this.searchRequest.request = value;
    this.searchResult = await firstValueFrom(this.searchService.searchControllerSearchAll(this.searchRequest));
    localStorage.setItem('search.request',value);
    localStorage.setItem('search.result', JSON.stringify(this.searchResult));
    this.loadingData = false;
  }

  async doSearch($event: KeyboardEvent) {
    // @ts-ignore
    const searchInput = $event.target.value as string;
    console.log('doSearch', searchInput);

    // Skip if ctrl or shift key is pressed
    if($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey || $event.keyCode === 91) {
      console.log('ctrl or shift key pressed');
      return;
    }
    if($event.keyCode === 8 && searchInput.length === 0) {
      console.log('backspace');
      this.clear(false);
      return;
    }
    if(searchInput.length >= 3) {
      await this.getData(searchInput);
    }else if(searchInput.length === 0) {
      this.clear();
    }
  }

  closeSearchModal(redirectLink: string | null = null): void {
    if(redirectLink) {
      this.dialogRef.close(redirectLink);
    }else {
      this.dialogRef.close(true);
    }
  }

  async openClientDetail(clientId: number, newWindow: boolean = false, $event: any) {
    $event.stopPropagation();
    if(newWindow) {
      window.open('/klienti/detail/' + clientId, '_blank');
      this.dialogRef.close(false);
      return;
    }
    this.closeSearchModal('/klienti/detail/' + clientId);
  }

  async openItemDialog(itemId: number, newWindow: boolean = false, $event: any) {
    $event.stopPropagation();
    if(newWindow) {
      window.open('/dila/detail/' + itemId, '_blank');
      this.dialogRef.close(false);
      return;
    }
    this.closeSearchModal('/dila/detail/' + itemId);
  }

  async openAuthorDetail(authorId: number, newWindow: boolean = false, $event: any) {
    $event.stopPropagation();
    if(newWindow) {
      window.open('/autori/detail/' + authorId, '_blank');
      this.dialogRef.close(false);
      return;
    }
    this.closeSearchModal('/autori/detail/' + authorId);
  }

  async openContractDetail(contractId: number, newWindow: boolean = false, $event: any) {
    $event.stopPropagation();
    if(newWindow) {
      window.open('/klienti/smlouva/detail/' + contractId, '_blank');
      this.dialogRef.close(false);
      return;
    }
    this.closeSearchModal('/klienti/smlouva/detail/' + contractId);
  }

  clear(includeInputField: boolean = true) {
    if(includeInputField) {
      this.searchRequest.request = '';
    }
    this.searchResult = {} as Search;
    localStorage.removeItem('search.request');
    localStorage.removeItem('search.result');
  }
}
