import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';


@Injectable({ providedIn: 'root' })
export class AdminGuard  {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if(state.url === '/uzivatele/profil/me')  return true;
    const currentUser = this.authService.currentUserValue;
    if (currentUser && currentUser.roles.some(role => role.name === 'Admin')) {
      return true;
    }
    this.router.navigate(['/error-500'], { queryParams: { returnUrl: state.url }});
    return false;
  }


}
