import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import {VexRoutes} from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import {AuthGuard} from './shared/guard/auth.guard';
import {AdminGuard} from "./shared/guard/admin.guard";

const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  },

  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: "nastenka",
        redirectTo: "/",
        pathMatch: "full",
      },
      {
        path: "",
        loadChildren: () =>  import("./pages/dashboard/dashboard.module").then((m) => m.DashboardModule ),
      },
      {
        path: 'dila',
        loadChildren: () => import('./pages/items/items.module').then(m => m.ItemsModule),
      },
      {
        path: 'klienti',
        loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule),
      },
      // {
      //   path: 'smlouvy',
      //   loadChildren: () => import('./pages/contracts/contracts.module').then(m => m.ContractsModule),
      // },
      {
        path: 'limitace',
        loadChildren: () => import('./pages/limitations/limitations.module').then(m => m.LimitationsModule),
      },
      {
        path: 'autori',
        loadChildren: () => import('./pages/authors/authors.module').then(m => m.AuthorsModule),
      },
      {
        path: 'nastaveni/uzivatele-systemu',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
      },

      {
        path: 'uzivatele',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
        canActivate: [AdminGuard],
      },

      {
        path: 'spolupracovnici',
        loadChildren: () => import('./pages/collaborators/collaborators.module').then(m => m.CollaboratorsModule),
      },

      {
        path: 'nastaveni/terminy-aukci',
        loadChildren: () => import('./pages/auctions/auctions.module').then(m => m.AuctionsModule),
      },
      {
        path: 'nastaveni/stitky',
        loadChildren: () => import('./pages/tags/tags.module').then(m => m.TagsModule),
      },
      {
        path: 'nastaveni/sablony-smluv',
        loadChildren: () => import('./pages/templates/templates.module').then(m => m.TemplatesModule),
      },
      {
        path: 'aplikace/preklady-obrazu',
        loadChildren: () => import('./pages/app-translate/app-translate.module').then(m => m.AppTranslateModule),
      },
      {
        path: 'aplikace/sprava-fotek',
        loadChildren: () => import('./pages/app-photograph/app-photograph.module').then(m => m.AppPhotographModule),
      },
      {
        path: 'aplikace/import-xml',
        loadChildren: () => import('./pages/app-xml-import/app-xml-import.component').then(m => m.AppXmlImportRoutingModule),
      },
      {
        path: 'blank',
        loadChildren: () => import('./pages/blank/blank.module').then(m => m.BlankModule),
      },
      {
        path: 'error-404',
        loadComponent: () =>
          import('./pages/errors/error-404/error-404.component').then(
            (m) => m.Error404Component
          )
      },
      {
        path: 'error-500',
        loadComponent: () =>
          import('./pages/errors/error-500/error-500.component').then(
            (m) => m.Error500Component
          )
      },
      {
        path: '**',
        loadComponent: () =>
          import('./pages/errors/error-404/error-404.component').then(
            (m) => m.Error404Component
          )
      }

    ],
    canActivate: [AuthGuard]
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
